import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { Portal } from '@/ui';

export const Confetti = (): JSX.Element => {
  const { width, height } = useWindowSize();

  return (
    <Portal appendToParentPortal>
      <ReactConfetti
        height={height}
        recycle={false}
        style={{ zIndex: 2000 }}
        width={width}
        colors={[
          '#E62350',
          '#EF6C2A',
          '#F8C83A',
          '#543CDE',
          '#2C88D9',
          '#207868',
          '#BC9055',
        ]}
      />
    </Portal>
  );
};
